* {
  padding: 0;
  margin: 0;
}

body {
  font-family: "Titillium Web", Arial, Helvetica, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Titillium Web", Arial, Helvetica, sans-serif;
  font-weight: 700;
}

header.banner {
  position: relative;
  background-color: #343a40;
  background: url("./assets/bg-banner.jpg") no-repeat center center;
  background-size: cover;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

header.banner .overlay {
  position: absolute;
  background-color: #212529;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.3;
}

header.banner h1 {
  font-size: 2rem;
}

@media (min-width: 768px) {
  header.banner {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
  header.banner h1 {
    font-size: 3rem;
  }
}

.btn-primary {
  background-color: #ec4d37;
  border-color: #ec4d37;
}

.btn-primary:hover,
.btn-primary.active {
  background-color: #f9d342;
  border-color: #f9d342;
}

.list-inline-item a {
  color: #ec4d37;
}

.invalid-input {
  color: #ec4d37;
}

.showcase .showcase-text {
  padding: 3rem;
}

.showcase .showcase-img {
  min-height: 30rem;
  background-size: cover;
  background-position: center 0;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .showcase .showcase-text {
    padding: 7rem;
  }
}

.services {
  padding-top: 2.5rem;
  padding-bottom: 5rem;
}

.services .service-item {
  max-width: 20rem;
}

.services .service-item i {
  font-size: 80px;
  display: block;
  margin-bottom: 15px;
  background: linear-gradient(270deg, #f9d342, #ec4d37);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.testimonials {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.testimonials .testimonial-item {
  max-width: 18rem;
}

.testimonials .testimonial-item img {
  width: 12rem;
  height: 12rem;
  object-fit: contain;
}

footer.footer {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

@media (max-width: 426px) {
  .nav-wrapper {
    justify-content: center !important;
    align-items: center !important;
  }
  .navbar-brand {
    margin: 0;
  }
  .brand-logo {
    width: 100%;
  }
}
